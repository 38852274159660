<template>
  <v-snackbar
    v-model="$store.state.app.snackBar.display"
    :timeout="10000"
    :color="snackbarColor"
    rounded="pill"
    elevation="6"
    class="modern-snackbar"
  >
    <div class="d-flex align-center">
      <v-icon v-if="snackbarIcon" left class="mr-2">{{ snackbarIcon }}</v-icon>
      <span>{{ snackbarText }}</span>
    </div>

    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="clickHandler"
        class="snackbar-action"
      >
        {{ snackbarButtonText }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  computed: {
    snackbarText() {
      const text = this.$store.state.app.snackBar.text;
      return typeof text === 'object' ? text.text : text;
    },
    snackbarButtonText() {
      const text = this.$store.state.app.snackBar.text;
      return typeof text === 'object' && text.button ? text.button : 'Close';
    },
    snackbarColor() {
      const text = this.$store.state.app.snackBar.text;
      if (typeof text === 'object' && text.type) {
        switch (text.type) {
          case 'success': return 'success';
          case 'error': return 'error';
          case 'warning': return 'warning';
          case 'info': return 'info';
          default: return 'primary';
        }
      }
      return 'primary';
    },
    snackbarIcon() {
      const text = this.$store.state.app.snackBar.text;
      if (typeof text === 'object' && text.type) {
        switch (text.type) {
          case 'success': return 'mdi-check-circle';
          case 'error': return 'mdi-alert-circle';
          case 'warning': return 'mdi-alert';
          case 'info': return 'mdi-information';
          default: return null;
        }
      }
      return null;
    }
  },
  methods: {
    clickHandler() {
      const text = this.$store.state.app.snackBar.text;
      if (typeof text === 'object' && text.action) {
        text.action();
      }
      this.$store.state.app.snackBar.display = false;
    }
  }
}
</script>

<style scoped>
.modern-snackbar {
  font-weight: 500;
}

.snackbar-action {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: transform 0.2s ease;
}

.snackbar-action:hover {
  transform: scale(1.05);
}
</style>
